<template>
  <VeeForm
    @submit="submitForm"
    v-slot="{ errors }"
    class="form tiny-input edit-form"
    novalidate
  >
    <mobile-screen :header="true" screen-class="gray-bg add-version">
      <template v-slot:header>
        <top-header-menu-wrapper menu-class="app-versions-header">
          <template v-slot:left>
            <router-link :to="{ name: 'r_application-versions' }">
              <button>
                <icon icon="#cx-hea1-edit-cancel" />
              </button>
            </router-link>
          </template>
          <div class="component-title">
            {{ selectedApplicationCopy.name }}
          </div>
          <template v-slot:right>
            <button>
              <icon icon="#cx-hea1-edit-done" />
            </button>
          </template>
        </top-header-menu-wrapper>
      </template>
      <ul class="clebex-item-section">
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="appName"
                >{{ displayLabelName("about", "app-version", "title") }}
              </label>
              <Field
                name="name"
                as="input"
                rules="required"
                type="text"
                id="appName"
                v-model="selectedApplicationCopy.name"
              />
              <span class="error-message" v-if="errors.name">
                {{ errors.name }}
              </span>
            </div>
          </div>
        </li>
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="versionNumber"
                >{{ displayLabelName("about", "app-version", "version") }}
              </label>
              <Field
                name="number"
                as="input"
                rules="required"
                type="text"
                id="versionNumber"
                v-model="version.number"
              />
              <span class="error-message" v-if="errors.number">
                {{ errors.number }}
              </span>
            </div>
          </div>
        </li>
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="versionDate"
                >{{ displayLabelName("about", "app-version", "date") }}
              </label>
              <Field
                name="date"
                as="input"
                rules="required"
                type="text"
                id="versionDate"
                v-model="version.date"
              />
              <span class="error-message" v-if="errors.date">
                {{ errors.date }}
              </span>
            </div>
          </div>
        </li>
      </ul>
      <ul class="clebex-item-section">
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="versionDescription"
                >{{ displayLabelName("about", "app-version", "release-notes") }}
              </label>
              <Field
                name="description"
                as="textarea"
                rules="required"
                rows="4"
                cols="50"
                type="text"
                id="versionDescription"
                v-model="version.description"
              />
              <span class="error-message" v-if="errors.description">
                {{ errors.description }}
              </span>
            </div>
          </div>
        </li>
      </ul>
    </mobile-screen>
  </VeeForm>
  <router-view />
</template>

<script>
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import MobileScreen from "@/layouts/MobileScreen";
import { mapActions, mapState } from "vuex";
import httpServiceAuth, { getSubdomain } from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

export default {
  name: "AddVersion",
  created() {
    if (this.subdomain || this.selectedApplication === null) {
      this.$router.push({ name: "r_about" });
    }
    this.selectedApplicationCopy = { ...this.selectedApplication };
  },
  data() {
    return {
      subdomain: getSubdomain(),
      version: {
        application_id: "",
        number: "",
        description: "",
        is_latest: true,
        date: dayjs().format("DD.MM.YYYY")
      },
      selectedApplicationCopy: null,
      applicationsCopy: null
    };
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  computed: {
    ...mapState("applications", [
      "applications",
      "selectedApplication",
      "selectedAppVersions"
    ])
  },
  methods: {
    ...mapActions("applications", [
      "setSelectedVersion",
      "setSelectedApplication",
      "setSelectedAppVersions",
      "setApplicationData",
      "getApplications"
    ]),
    submitForm(values, { resetForm }) {
      if (values) {
        this.submitMasterData(values, resetForm);
      }
    },
    submitMasterData(values, resetForm) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      if (this.selectedApplicationCopy.name === this.selectedApplication.name) {
        this.setVersions(resetForm);
      } else {
        httpServiceAuth
          .put(
            `${apiEndpoints.master.applications}/${this.selectedApplicationCopy.id}`,
            { name: values.name }
          )
          .then(() => {
            this.setSelectedApplication(this.selectedApplicationCopy);
            this.setVersions(resetForm);
            this.$store.commit("loader/setScreenLoading", false, {
              root: true
            });
          })
          .catch(error => {
            if (error.response) {
              errorHandler(error.response, this.findElement());
            }
          })
          .finally(() => {
            this.$store.commit("loader/setScreenLoading", false, {
              root: true
            });
          });
      }
    },
    setVersions(resetForm) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      this.version.application_id = this.selectedApplicationCopy.id;
      this.version.date = dayjs(this.version.date, "DD.MM.YYYY").format(
        "YYYY-MM-DD"
      );
      httpServiceAuth
        .post(apiEndpoints.master.versions, this.version)
        .then(res => {
          this.setSelectedVersion(res.data.data);
          let newSelectedAppVersions = this.selectedAppVersions;
          newSelectedAppVersions.push(res.data.data);
          this.setSelectedAppVersions(newSelectedAppVersions);
          resetForm();
          this.$router.push({ name: "r_application-versions" });
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        })
        .catch(err => {
          if (err.response) {
            errorHandler(err.response, this.findElement());
          }
        })
        .finally(() => {
          this.getAppInfo();
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getAppInfo() {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      this.getApplications()
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    }
  }
};
</script>
